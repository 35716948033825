<template>
  <div>
    <van-nav-bar
      title="重大危险源"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    />
    <van-search
      v-model="searchData.dangerNameAndLeave"
      placeholder="请输入重大危险源名称或等级"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <DangerList ref="danger-list" :api="getDangersList" />
  </div>
</template>

<script>
import DangerList from "./components/DangerList.vue";

import { getDangersList } from "./api";
export default {
  components: {
    DangerList
  },
  data() {
    return {
      searchData: {
        dangerNameAndLeave: ""
      }
    };
  },
  methods: {
    getDangersList(query = {}) {
      return getDangersList({ ...this.searchData, ...query });
    },
    onSearch() {
      this.$refs["danger-list"].getList(true);
    }
  }
};
</script>
