<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div
      v-for="(item, idx) in list"
      :key="idx"
      class="danger-item"
      @click="gotoDetails(item)"
    >
      <div class="danger-title">
        {{ item.name }}
        <span
          class="danger-status"
          :style="{ background: colorMap[item.checkStatus] }"
          >{{ item.checkStatusName }}</span
        >
      </div>
      <div class="danger-body">
        <span class="danger-span">{{ item.departmentName }}</span>
        <span class="danger-span">{{ item.levelName }}</span>
        <span class="danger-span">{{ item.code }}</span>
      </div>
    </div>
  </LoadListView>
</template>

<script>
const colorMap = {
  0: "#1890FF",
  1: "#F6790F",
  2: "#67C23A",
  3: "#FA5555"
};
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    colorMap: () => colorMap
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoDetails(item) {
      this.$router.push({ name: "danger-detail", params: { id: item.id } });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.danger {
  &-item {
    padding: 10px 16px;
    border-bottom: 8px solid #eceef2;
    background-color: #fff;
  }

  &-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;
  }

  &-body {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
    margin-top: 4px;
  }

  &-status {
    padding: 0 4px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    border-radius: 2px;
  }

  &-span {
    border-left: 1px solid #a0a2a6;
    padding: 0 10px;

    &:nth-of-type(1) {
      padding-left: 0;
      border: none;
    }

    &:nth-last-of-type(1) {
      padding-right: 0;
    }
  }
}
</style>
